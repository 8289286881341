import React from "react";
import fr from "../../assets/img/logo/fr.png";
import tn from "../../assets/img/logo/TUN.png";
import localisation from "../../assets/img/logo/outil.png";
import phone from "../../assets/img/logo/phone.png";
import email from "../../assets/img/logo/email.png";
const ContactInfo = () => {
  return (
    <div>
      <div>
        <div>
          <p
            style={{
              marginBottom: 0,
              fontFamily: "Merriweather",
              color: "blue",
              fontSize: "30px",
            }}
          >
            FRANCE
          </p>
          <img src={fr} alt="fr" width="80" height="50" />
        </div>
        <br />
        <div className="loc">
          <a href="https://www.google.com/maps/place/BKCE+international/@48.8426418,2.618949,16z/data=!4m5!3m4!1s0x0:0x28139438b4bd1c57!8m2!3d48.8427209!4d2.6198142">
            <img
              src={localisation}
              alt="fr"
              width="30"
              height="30"
              className="icon"
            />
          </a>
          <a href="https://www.google.com/maps/place/BKCE+international/@48.8426418,2.618949,16z/data=!4m5!3m4!1s0x0:0x28139438b4bd1c57!8m2!3d48.8427209!4d2.6198142">
            <p>11 Grande Allée de 12 Février 1934 -77186 Noisiel France.</p>
          </a>
        </div>
        <br />
        <div className="loc">
          <img src={phone} alt="fr" width="30" height="30" />
          <p>+33 615 139 967</p>
        </div>
        <br />
        <div className="loc">
          <img src={email} alt="fr" width="30" height="30" className="icon" />
          <p>roissy.export@bkceintl.com</p>
        </div>
      </div>
      <br />
      <div>
        <div>
          <p
            style={{
              marginBottom: 0,
              fontFamily: "Merriweather",
              color: "red",
              fontSize: "30px",
            }}
          >
            TUNISIA
          </p>
          <img src={tn} alt="fr" width="90" height="50" className="icon" />
        </div>
        <br />
        <div className="loc">
          <a href="https://www.google.com/maps/place/BKCE+international/@48.8426418,2.618949,16z/data=!4m5!3m4!1s0x0:0x28139438b4bd1c57!8m2!3d48.8427209!4d2.6198142">
            <img
              src={localisation}
              alt="fr"
              width="30"
              height="30"
              className="icon"
            />
          </a>
          <a href="https://www.google.com/maps/place/BKCE+international/@48.8426418,2.618949,16z/data=!4m5!3m4!1s0x0:0x28139438b4bd1c57!8m2!3d48.8427209!4d2.6198142">
            <p>81 Avennue Habib Bourguiba2080 Ariana, Tunis</p>
          </a>
        </div>
        <br />
        <div className="loc">
          <img src={phone} alt="fr" width="30" height="30" />
          <p>+ 216 50 966 380</p>
        </div>
        <br />
        <div className="loc">
          <img src={email} alt="fr" width="30" height="30" className="icon" />
          <p>info@bkceintl.com</p>
        </div>
        <br />
        <br />
      </div>
    </div>
  );
};

export default ContactInfo;
