import React from "react";

const ClientsList = () => {
  return (
    <div className="client-container">
      <div className="client-item box">
        <div className="">
          <img
            src="assets/img/demo/geo.jpg"
            alt="Logo"
            width="120"
            height="100"
          />
        </div>
      </div>

      <div className="client-item box">
        <div className="client-body">
          <img
            src="assets/img/demo/11_img.png"
            alt="Logo"
            width="90"
            height="100"
          />
        </div>
      </div>

      <div className="client-item box">
        <div className="client-body">
          <img
            src="assets/img/demo/15_img.png"
            alt="Logo"
            width="90"
            height="100"
          />
        </div>
      </div>

      <div className="client-item box">
        <div className="client-body">
          <img
            src="assets/img/demo/12_img.png"
            alt="Logo"
            width="90"
            height="100"
          />
        </div>
      </div>

      <div className="client-item box">
        <div className="client-body">
          <img
            src="assets/img/demo/14_img.png"
            alt="Logo"
            width="90"
            height="100"
          />
        </div>
      </div>
      <div className="client-item box">
        <div className="client-body">
          <img
            src="assets/img/demo/16_img.png"
            alt="Logo"
            width="90"
            height="100"
          />
        </div>
      </div>
      <div className="client-item box">
        <div className="client-body">
          <img
            src="assets/img/demo/fedex.jpg"
            alt="Logo"
            width="90"
            height="100"
          />
        </div>
      </div>
      <div className="client-item box">
        <div className="client-body">
          <img
            src="assets/img/demo/dhl.png"
            alt="Logo"
            width="90"
            height="100"
          />
        </div>
      </div>
      <div className="client-item box">
        <div className="client-body">
          <img
            src="assets/img/demo/LEC.png"
            alt="Logo"
            width="90"
            height="100"
          />
        </div>
      </div>
      <div className="client-item box">
        <div className="client-body">
          <img
            src="assets/img/demo/VIA.webp"
            alt="Logo"
            width="90"
            height="100"
          />
        </div>
      </div>
      <div className="client-item box">
        <div className="client-body">
          <img
            src="assets/img/demo/chro.png"
            alt="Logo"
            width="100"
            height="100"
          />
        </div>
      </div>
    </div>
  );
};

export default ClientsList;
