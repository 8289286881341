import React from "react";
import FooterMenu from "../footer/FooterMenu";
import logoinsta from "../../assets/img/logo/instagram.png";
import logotwt from "../../assets/img/logo/twitter.png";
import logolinkedin from "../../assets/img/logo/linkedin.png";
import localisation from "../../assets/img/logo/localisation.png";

const FooterWidget = () => {
  return (
    <div className="footer-widgets">
      <div className="footer-widget-area d-flex flex-wrap">
        <div className="widget">
          <h5>Social Media</h5>

          <a
            href="https://www.instagram.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="logomargin"
              src={logoinsta}
              alt="logo"
              width="30"
              height="30"
            />
          </a>

          <a
            href="https://twitter.com/BkceI"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="logomargin"
              src={logotwt}
              alt="logo"
              width="30"
              height="30"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/80559730/admin/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="logomargin"
              src={logolinkedin}
              alt="logo"
              width="30"
              height="30"
            />
          </a>
          <a
            href="https://www.google.com/maps/place/BKCE+international/@48.8426418,2.618949,16z/data=!4m5!3m4!1s0x0:0x28139438b4bd1c57!8m2!3d48.8427209!4d2.6198142"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="logomargin"
              src={localisation}
              alt="logo"
              width="30"
              height="30"
            />
          </a>
        </div>
        <div className="widget">
          <h5 className="widget-title">Menu</h5>

          <FooterMenu />
        </div>
        <div className="widget">
          <h5 className="widget-title">Contacts</h5>

          <p>81, Avenu Habib Bourguiba2080 Ariana, Tunis</p>

          <p>
            <a href="mailto:info@bkceintl.com">info@bkceintl.com</a>
          </p>

          <p>+216 50 966 380</p>
          <p>+33 615 139 967</p>
        </div>
      </div>
    </div>
  );
};

export default FooterWidget;
