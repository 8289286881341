import React from "react";
import { Link } from "react-router-dom";
import tick from "../../assets/img/bg/blueTick.png";
const ServiceInsideWidget = ({ order }) => {
  return (
    <div className="card" style={{ borderColor: "#185B85" }}>
      <div className="card-body">
        <h5 style={{ color: "#185B85" }} className="card-title">
          Service included
        </h5>

        <div className="card-text">
          {order &&
            order.map((item) => (
              <p
                className="tick"
                style={{ fontFamily: "Merriweather", fontSize: "18px" }}
              >
                <img
                  src={tick}
                  alt="tickimg"
                  width="20px"
                  style={{ marginRight: "15px" }}
                ></img>
                {item}
              </p>
            ))}
        </div>
      </div>

      <div className="card-link">
        <Link
          className="btn btn-secondary btn-lg w-100"
          to={process.env.PUBLIC_URL + "/contacts"}
          title="Contacts"
          style={{ backgroundColor: "#185B85", borderColor: "#185B85" }}
        >
          Order plan
        </Link>
      </div>
    </div>
  );
};

export default ServiceInsideWidget;
