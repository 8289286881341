import React from "react";
import aircraft from "../../assets/img/placeholder/Aircraft.png";
import ship from "../../assets/img/placeholder/Ship.png";
import cargo from "../../assets/img/placeholder/Cargo.png";
import yacht from "../../assets/img/placeholder/finalyacht.png";
import ex from "../../assets/img/placeholder/Ex.png";
import trade from "../../assets/img/placeholder/tradefinal.png";
import oil from "../../assets/img/placeholder/Oil.png";
import whatsapp from "../../assets/img/placeholder/Whatsapp.png";
import map from "../../assets/img/placeholder/Map.png";
import ClientsList from "../clients/ClientsList";

const WhyUsContent = () => {
  return (
    <div className="wrapper">
      <div className="content">
        <div className="clearfix">
          <div style={{ fontFamily: "Merriweather" }} className="text-align">
            <p>
              BKCE is a one stop logistics solutions provider with diversified
              services. we believe in listening to our customers,
              understanding their specific requirements and delivering cost
              effective solutions, while allowing them to stay focused on their
              core business activities. our ultimate goal is customer delight.
              we strive to achieve this by focusing on quick problem solving,
              prompt response, personalized attention, and collaborative
              customer relationship
            </p>
          </div>
          <br />
          <br />
          <br />
          <div className="row gutter-width-lg with-pb-lg style-default text-align ">
            <div
              className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12"
              style={{ fontFamily: "Merriweather" }}
            >
              <img src={aircraft} alt="aircraft" />

              <p
                className="text-primary p-large "
                style={{ fontFamily: "Merriweather", marginTop: "12px" }}
              >
                AIRCRAFT ON GROUND
              </p>
              <p className="bold bord"></p>
              <p>
                BKCE with a proven track record of success in this operational
                serve various airlines for on time delivery of time -critical
                aircraft parts. We have earned the reputation of being an
                ex-perpended AOG service provider
              </p>
            </div>

            <div
              className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12"
              style={{ fontFamily: "Merriweather" }}
            >
              <img src={ship} alt="ship" />
              <p
                className="text-primary p-large"
                style={{ fontFamily: "Merriweather", marginTop: "8px" }}
              >
                SHIP SPARES
              </p>
              <p className="bold bord" style={{ marginBottom: "26px" }}></p>
              <p>
                BKCE has the expertise to pick ship spares and deliver to
                waiting ships on 24/7 basis. We ensure that all required
                procedures and special permissions from the Goverment
                authorities.
              </p>
            </div>

            <div
              className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12"
              style={{ fontFamily: "Merriweather" }}
            >
              <img src={cargo} alt="cargo" />
              <p
                className="text-primary p-large"
                style={{ fontFamily: "Merriweather" }}
              >
                PROJECT CARGO
              </p>
              <p className="bold bord" style={{ marginBottom: "26px" }}></p>
              <p style={{ fontFamily: "Merriweather" }}>
                We have the technical know3how and experience to handle project
                cargo movements related to any industry.
              </p>
            </div>

            <div
              className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12"
              style={{ fontFamily: "Merriweather" }}
            >
              <img src={oil} alt="liquid" />
              <br />
              <p
                className="text-primary p-large "
                style={{ fontFamily: "Merriweather", marginTop: "8px" }}
              >
                GAZ AND OIL TRANSPORTATION
              </p>
              <p className="bold bord"></p>
              <p>
                BKCE is the appointed agent for multinational Industrial Gas and
                Oil manufactures. Supported with qualified professionals to
                handle special shipments with care.BKCE is a pioneer in this
                unique segment.
              </p>
            </div>

            <div
              className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12"
              style={{ fontFamily: "Merriweather" }}
            >
              <img src={ex} alt="ex" />
              <p
                className="text-primary p-large "
                style={{ fontFamily: "Merriweather" }}
              >
                EXHIBITONS
              </p>
              <p className="bold bord"></p>
              <p>
                Ensuring on time pick/up, delivery and set up on the site,
                removal and re/export activities are carried out with high
                efficiency and promptness.
              </p>
            </div>

            {/* <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
              <WhyUsPdf />
            </div> */}
            <div
              className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12"
              style={{ fontFamily: "Merriweather" }}
            >
              <img src={trade} alt="trade" style={{ marginBottom: "30px" }} />
              <p
                className="text-primary p-large "
                style={{ fontFamily: "Merriweather", marginTop: "3px" }}
              >
                TRADE FACILITATION
              </p>
              <p className="bold bord"></p>
              <p>
                By offering an alternative choice to our Client and Agents,BKCE
                fulfill all commercial requirements.
              </p>
            </div>
            <div
              className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12"
              style={{
                fontFamily: "Merriweather",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <img src={yacht} alt="gas" width="60%" />
              <br />
              <br />
              <p
                className="text-primary p-large bold bord"
                style={{ fontFamily: "Merriweather" }}
              >
                YACHT AND LUXURY VEHICLES
              </p>
              <p>
                BKCE has great depth of experience over the years In the
                transportation of luxury yachts and all types Of vehicles. We
                carryout lashing, securing and ens Sure safe delivery.
              </p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />

      <div className="bigcontain">
        <div>
          <h4>CALL US NOW ON</h4>
        </div>
        <br />
        <br />
        <div className="contain">
          <img src={whatsapp} alt="whatsup" width="8%" height="" />
          <div className="cont">
            <p>+33 615 139 967 </p>
            <p>+216 50 966 380</p>
          </div>
        </div>
        <br />
        <br />
        <hr />
        <div>
          <img src={map} alt="mpa" width="100%" />
        </div>
      </div>
      <section id="clients" className="block spacer p-top-xl">
        <ClientsList />
      </section>
    </div>
  );
};

export default WhyUsContent;
